exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-layouts-documents-jsx": () => import("./../../../src/layouts/documents.jsx" /* webpackChunkName: "component---src-layouts-documents-jsx" */),
  "component---src-layouts-news-jsx": () => import("./../../../src/layouts/news.jsx" /* webpackChunkName: "component---src-layouts-news-jsx" */),
  "component---src-layouts-recrutation-jsx": () => import("./../../../src/layouts/recrutation.jsx" /* webpackChunkName: "component---src-layouts-recrutation-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-documents-js": () => import("./../../../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parents-councel-js": () => import("./../../../src/pages/parents-councel.js" /* webpackChunkName: "component---src-pages-parents-councel-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-psycho-care-js": () => import("./../../../src/pages/psycho-care.js" /* webpackChunkName: "component---src-pages-psycho-care-js" */),
  "component---src-pages-recrutation-js": () => import("./../../../src/pages/recrutation.js" /* webpackChunkName: "component---src-pages-recrutation-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

